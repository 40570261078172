import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { CraftGlobals } from 'tsconfig/craft-types'
import { C404 } from 'ui'
import { getSiteInfo } from 'shared'
import getGlobals from '../graphql/get-globals'
import DefaultLayout from '../layouts/default'

type Context = {
  locale: string
}

export async function getStaticProps(context: Context) {
  const siteInfo = getSiteInfo(context.locale)
  const global = await getGlobals(siteInfo.handle)

  return {
    props: {
      ...(await serverSideTranslations(context.locale, ['common'])),
      global,
    },
  }
}

type Props = {
  global: CraftGlobals
}

export default function Error404({ global }: Props) {
  return (
    <DefaultLayout global={global}>
      <C404 />
    </DefaultLayout>
  )
}
